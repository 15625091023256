import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Layouts from "./mixins/layouts.mixin";
import { BootstrapVue } from "bootstrap-vue";
import './sass/app.scss'
import vClickOutside from "v-click-outside";
import VueMask from "v-mask";
// import './components/global-components'
import tinymce from "vue-tinymce-editor";
import i18n from "./i18n";
import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import ApiClient from "@/mixins/ApiClient";
import vuetify from './plugins/vuetify'

//
// import "diagram-maker/dist/diagramMaker.css";

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});
Vue.component("apexchart", VueApexCharts);
Vue.component("tinymce", tinymce);
Vue.mixin(ApiClient)

new Vue({
  router,
  store,
  mixins: [Layouts],
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
