import axios from "axios";

class GetLatestInfrastructureRevisionHandler{

    async handle(getLatestInfrastructureRevision) {
        const response = await axios.get(process.env.VUE_APP_API_URL + "infrastructurerevision/" + getLatestInfrastructureRevision.projectUuid,{
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("DB_TOKEN")
            }
        })

        return response.data;
    }
}

const getLatestInfrastructureRevisionHandler = new GetLatestInfrastructureRevisionHandler();
export default getLatestInfrastructureRevisionHandler;
