import Vue from 'vue'
import Vuex from 'vuex'
import getLatestInfrastructureRevisionHandler from "@/queryhandler/GetLatestInfrastructureRevisionHandler";
import GetLatestInfrastructureRevision from "@/query/GetLatestInfrastructureRevision";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    infrastructureRevision: null
  },
  getters: {
    getInfrastructureRevision(){
      return this.state.infrastructureRevision
    }
  },
  mutations: {
    setInfrastructureRevision(state, infrastructureRevision){
      state.infrastructureRevision = infrastructureRevision
    }
  },
  actions: {
    async getInfrastructureRevision({commit}, projectUuid){
      this.infrastructureRevision = null
      const infrastructureRevision = await getLatestInfrastructureRevisionHandler.handle(
          new GetLatestInfrastructureRevision(projectUuid)
      )

      if(infrastructureRevision.infrastructureRevision != null){
        infrastructureRevision.infrastructureRevision.isNew = false
      }

      commit("setInfrastructureRevision", infrastructureRevision.infrastructureRevision)

      return infrastructureRevision.infrastructureRevision
    },
    setInfrastructureRevision({commit}, infrastructureRevision){
      commit("setInfrastructureRevision", infrastructureRevision)
    }
  },
  modules: {
  }
})
