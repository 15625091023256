import Vue from 'vue'
import VueRouter from 'vue-router'
import {requireLoggedInCheck} from "@/router/authGuard";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "about" */ '../views/LoginView')
    },
    {
        path: '/verify',
        component: () => import(/* webpackChunkName: "about" */ '../views/VerifyUserView')
    },
    {
        path: '/logout',
        beforeEnter: requireLoggedInCheck,
        component: () => import(/* webpackChunkName: "about" */ '../views/LogoutView')
    },
    {
        path: '/register',
        component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView')
    },
    {
        path: '/',
        beforeEnter: requireLoggedInCheck,
        component: () => import(/* webpackChunkName: "about" */ '../views/organisation/OrganisationsView'),
    },
    {
        path: '/organisation',
        beforeEnter: requireLoggedInCheck,
        component: () => import(/* webpackChunkName: "about" */ '../views/organisation/OrganisationsView'),
    },
    {
        path: '/organisation/new',
        beforeEnter: requireLoggedInCheck,
        component: () => import(/* webpackChunkName: "about" */ '../views/organisation/CreateOrganisationView'),
    },
    {
        path: '/organisation/:organisationUuid',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/project/ProjectsView')
    },
    {
        path: '/organisation/:organisationUuid/user',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/organisation/OrganisationUsersView')
    },
    {
        path: '/organisation/:organisationUuid/user/new',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/organisation/InviteOrganisationUserView')
    },
    {
        path: '/organisation/:organisationUuid/update',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/organisation/OrganisationView')
    },
    {
        path: '/organisation/:organisationUuid/project',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/project/ProjectsView'),
        // children: [
        //     /*{
        //         path: '',
        //         component: () => import(/!* webpackChunkName: "about" *!/ '../views/project/ProjectsView')
        //     },*/
        //     {
        //         path: ':projectId',
        //         props: true,
        //         component: () => import(/* webpackChunkName: "about" */ '../views/project/ProjectView'),
        //         children: [
        //             // {
        //             //     path: '',
        //             //     component: () => import(/* webpackChunkName: "about" */ '../views/project/ProjectView'),
        //             // },
        //             {
        //                 path: 'infra',
        //                 component: () => import(/* webpackChunkName: "about" */ '../views/setup/SetUp')
        //             },
        //         ]
        //     },
        // ]
    },
    {
        path: '/organisation/:organisationUuid/project/new',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/project/CreateProjectView'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/infrastructurerevision/launch/Wizard'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/infra',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/infrastructurerevision/launch/Wizard'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/infra/builds',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/infrastructurerevision/build/BuildList'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/infra/:infrastructureRevisionUuid/status',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/infrastructurerevision/status/Status'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/diagram/components',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/ArchitectureDiagramView'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/diagram',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/DiagramView'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/cost',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/CostView'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/container',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/container/ContainersView'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/container/:serviceUuid',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/container/ContainerView'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/database',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/DatabaseViewer'),
    },
    {
        path: '/organisation/:organisationUuid/project/:projectUuid/subscription',
        beforeEnter: requireLoggedInCheck,
        props: true,
        component: () => import(/* webpackChunkName: "about" */ '../views/SubscriptionView'),
    },
    {
        path: '/knowledge/how-to-get-a-github-token',
        component: () => import('../views/knowledge/HowToGetAGithubToken'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router


// {
//     path: 'project/:projectId/infra',
//     component: () => import(/* webpackChunkName: "about" */ '../views/setup/SetUp')
// },
// {
//     path: 'project/:projectId/diagram',
//     component: () => import(/* webpackChunkName: "about" */ '../views/ArchitectureDiagramView')
// },
// {
//     path: 'project/:projectId/cost',
//     component: () => import(/* webpackChunkName: "about" */ '../views/CostView')
// },
// {
//     path: 'project/:projectId/container',
//     component: () => import(/* webpackChunkName: "about" */ '../views/ContainerView')
// },
