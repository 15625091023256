import axios from "axios";

export default {
    methods: {
        $http(url, method, data, config = {}){
            let baseUrl = process.env.VUE_APP_API_URL

            return new Promise((resolve, reject) => {
                axios({
                    url: baseUrl + url,
                    method: method,
                    data: data,
                    config: config,
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("DB_TOKEN")
                    },
                })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if(error.response.status === 401){
                            localStorage.removeItem("DB_TOKEN")
                            this.$router.push("/login")
                        }

                        reject(error);
                    });
            });
        }
    }
}
